import React, { FunctionComponent } from 'react';
import { ConclusionReportItem } from './interfaces/ConclusionReportItem';
import { TableType } from '../../../../../../enums';
import { ConclusionReportTemplate } from './ConclusionReportTemplate';
import { Stack } from '@fluentui/react';

type ConclusionReportsProps = {
    reports: { [key: string]: ConclusionReportItem[] };
};

export const ConclusionReportsTemplate: FunctionComponent<ConclusionReportsProps> = ({ reports }: ConclusionReportsProps) => {
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            {/*BGL360 Table Types*/}
            {reports[TableType[TableType.BGL360FinancialPosition]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.BGL360FinancialPosition}
                    items={reports[TableType[TableType.BGL360FinancialPosition]]}
                />
            )}
            {reports[TableType[TableType.BGL360OperatingStatement]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.BGL360OperatingStatement}
                    items={reports[TableType[TableType.BGL360OperatingStatement]]}
                />
            )}
            {reports[TableType[TableType.BGL360InvestmentIncome]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.BGL360InvestmentIncome}
                    items={reports[TableType[TableType.BGL360InvestmentIncome]]}
                />
            )}
            {reports[TableType[TableType.BGL360InvestmentMovement]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.BGL360InvestmentMovement}
                    items={reports[TableType[TableType.BGL360InvestmentMovement]]}
                />
            )}
            {reports[TableType[TableType.BGL360InvestmentSummary]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.BGL360InvestmentSummary}
                    items={reports[TableType[TableType.BGL360InvestmentSummary]]}
                />
            )}
            {reports[TableType[TableType.BGL360RealisedCapitalGains]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.BGL360RealisedCapitalGains}
                    items={reports[TableType[TableType.BGL360RealisedCapitalGains]]}
                />
            )}

            {/*Class Table Types*/}
            {reports[TableType[TableType.ClassSuperStatmentofFP]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.ClassSuperStatmentofFP}
                    items={reports[TableType[TableType.ClassSuperStatmentofFP]]}
                />
            )}
            {reports[TableType[TableType.ClassSuperOperatingStatement]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.ClassSuperOperatingStatement}
                    items={reports[TableType[TableType.ClassSuperOperatingStatement]]}
                />
            )}
            {reports[TableType[TableType.ClassSuperInvestmentIncome]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.ClassSuperInvestmentIncome}
                    items={reports[TableType[TableType.ClassSuperInvestmentIncome]]}
                />
            )}
            {reports[TableType[TableType.ClassSuperInvestmentMovement]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.ClassSuperInvestmentMovement}
                    items={reports[TableType[TableType.ClassSuperInvestmentMovement]]}
                />
            )}
            {reports[TableType[TableType.ClassSuperInvestmentSummary]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.ClassSuperInvestmentSummary}
                    items={reports[TableType[TableType.ClassSuperInvestmentSummary]]}
                />
            )}
            {reports[TableType[TableType.ClassSuperRealisedCapitalGainsReport]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.ClassSuperRealisedCapitalGainsReport}
                    items={reports[TableType[TableType.ClassSuperRealisedCapitalGainsReport]]}
                />
            )}
            {reports[TableType[TableType.ClassSuperHoldingsBalanceReconciliation]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.ClassSuperHoldingsBalanceReconciliation}
                    items={reports[TableType[TableType.ClassSuperHoldingsBalanceReconciliation]]}
                />
            )}

            {/*Supermate Table Types*/}
            {reports[TableType[TableType.SupermateFinancialPosition]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.SupermateFinancialPosition}
                    items={reports[TableType[TableType.SupermateFinancialPosition]]}
                />
            )}
            {reports[TableType[TableType.SupermateOperating]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.SupermateOperating}
                    items={reports[TableType[TableType.SupermateOperating]]}
                />
            )}
            {reports[TableType[TableType.SupermateInvestmentIncome]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.SupermateInvestmentIncome}
                    items={reports[TableType[TableType.SupermateInvestmentIncome]]}
                />
            )}
            {reports[TableType[TableType.SupermateInvestmentMovement]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.SupermateInvestmentMovement}
                    items={reports[TableType[TableType.SupermateInvestmentMovement]]}
                />
            )}
            {reports[TableType[TableType.SupermateInvestmentSummary]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.SupermateInvestmentSummary}
                    items={reports[TableType[TableType.SupermateInvestmentSummary]]}
                />
            )}
            {reports[TableType[TableType.SupermateRealisedCapitalGains]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.SupermateRealisedCapitalGains}
                    items={reports[TableType[TableType.SupermateRealisedCapitalGains]]}
                />
            )}

            {/*BGL Table types*/}
            {reports[TableType[TableType.BGLFinancialPosition]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.BGLFinancialPosition}
                    items={reports[TableType[TableType.BGLFinancialPosition]]}
                />
            )}
            {reports[TableType[TableType.BGLOperating]]?.length && (
                <ConclusionReportTemplate tableType={TableType.BGLOperating} items={reports[TableType[TableType.BGLOperating]]} />
            )}
            {reports[TableType[TableType.BGLInvestmentIncome]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.BGLInvestmentIncome}
                    items={reports[TableType[TableType.BGLInvestmentIncome]]}
                />
            )}
            {reports[TableType[TableType.BGLInvestmentMovement]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.BGLInvestmentMovement}
                    items={reports[TableType[TableType.BGLInvestmentMovement]]}
                />
            )}
            {reports[TableType[TableType.BGLInvestmentSummary]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.BGLInvestmentSummary}
                    items={reports[TableType[TableType.BGLInvestmentSummary]]}
                />
            )}
            {reports[TableType[TableType.BGLInvestmentDisposal]]?.length && (
                <ConclusionReportTemplate
                    tableType={TableType.BGLInvestmentDisposal}
                    items={reports[TableType[TableType.BGLInvestmentDisposal]]}
                />
            )}

            {/*Fallback*/}
            <div></div>
        </Stack>
    );
};
