import { useBoolean } from '@fluentui/react-hooks';
import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

interface ICalloutContext {
    target: string | undefined;
	isVisibleOnTarget: (onTarget: string) => boolean;
    show: (target: string) => void;
    hide: () => void;
}

const CalloutContext = createContext<ICalloutContext>({
	target: undefined,
	isVisibleOnTarget: (onTarget: string) => false,
	show: (target: string) => {},
	hide: () => {},
})

export const useCalloutContext = (): ICalloutContext => {
	return useContext(CalloutContext);
}

export const CalloutProvider = ({
	                                children,
	                                ...otherProps
                                }: PropsWithChildren<{}>) => {

	const [isVisible, { setTrue, setFalse }] = useBoolean(false);

	const [target, setTarget] = useState<string | undefined>(undefined);
	
	const show = (onTarget: string) => {
		setTarget(onTarget);
		setTrue();
	}
	
	const hide = () => {
		setFalse();
		setTarget(undefined);
	}
	
	const isVisibleOnTarget = useCallback((onTarget: string) => {
		return target === onTarget && isVisible;
	}, [isVisible, target]);
	
	return (
		<CalloutContext.Provider value={{
			target: target,
			isVisibleOnTarget: isVisibleOnTarget,
			show: show,
			hide: hide,
		}}>
			{children}
		</CalloutContext.Provider>
	)
}