import {IButtonStyles, IconButton, useTheme} from "@fluentui/react";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {FunctionComponent, useEffect, useState} from "react";
import {Modal} from "../../../../components";
import {JobOmlForm} from "./JobOmlForm";
import {useIntl} from "react-intl";
import {TableType} from "../../../../enums";
import {IJobOmlItem} from "./interfaces/IJobOmlItem";
import {useSectionContext} from "../Section";
import {JobOmlPreview} from "./JobOmlPreview";
import {useTabContext} from "../../JobPortalPage";
import { InitialAuditQueriesSectionReference } from "../templates/procedures/AuditQueries";
import { useCalloutContext } from "../../../../components/callout";

type JobOmlsProps = {
    hasOmls: boolean;
    tableType: TableType;
    itemId: number;
    onAfterSubmit?: (items: IJobOmlItem[]) => void;
    disabled?: boolean;
    showRaisedItems?: boolean;
    showNonWitholdingColumn?: boolean;
    formDisabled?: boolean;
    silentRefresh?: boolean;
}
export const JobOmls: FunctionComponent<JobOmlsProps> = ({
                                                             hasOmls,
                                                             itemId,
                                                             tableType,
                                                             onAfterSubmit,
                                                             disabled,
                                                             showRaisedItems = true,
                                                             showNonWitholdingColumn = true,
                                                             formDisabled = false, 
                                                             silentRefresh = false
                                                         }) => {
    const buttonId = useId('callout-button');
    const theme = useTheme();
    const {formatMessage} = useIntl();
    const [isOpenModal, {toggle: toggleOpenModal}] = useBoolean(false);
    const {show: showPreview, hide: hidePreview, isVisibleOnTarget} = useCalloutContext();

    const [isPointerOverPreview, setIsPointerOverPreview] = useState<boolean>(false);

    const {refresh, section} = useSectionContext();
    const {refreshSection} = useTabContext();

    const styles: IButtonStyles = {
        root: {
            color: hasOmls ? theme.palette.red : theme.palette.themePrimary,
        },
        rootHovered: {
            color: hasOmls ? theme.palette.redDark : theme.palette.themeDarker
        },
        rootPressed: {
            color: hasOmls ? theme.palette.red : theme.palette.themePrimary,
        }
    }

    const handlePointerEnter = () => {
        setIsPointerOverPreview(true);
    };

    const handlePointerLeave = () => {
        setIsPointerOverPreview(false);
    };

    useEffect(() => {
        if (!isPointerOverPreview && isVisibleOnTarget(buttonId)) {
            hidePreview()
        }
    }, [isPointerOverPreview, buttonId])

    const refreshParent = (silent?: boolean) => {
        refresh?.(silent)
        refreshSection(InitialAuditQueriesSectionReference, silent)
    };

    return (
        <>
            <IconButton id={buttonId}
                        styles={styles}
                        disabled={disabled}
                        onClick={toggleOpenModal}
                        onPointerEnter={() => showPreview(buttonId)}
                        iconProps={{iconName: 'AlertSolid'}}
            />
            {isVisibleOnTarget(buttonId) && hasOmls && (<JobOmlPreview isPointerOver={isPointerOverPreview}
                                                            itemId={itemId}
                                                            target={buttonId}
                                                            tableType={tableType}
                                                            onPointerEnter={handlePointerEnter}
                                                            onPointerLeave={handlePointerLeave}
            />)}
            <Modal resize={'both'} isOpen={isOpenModal} isBlocking={true} isDraggable={true} onDismiss={() => {
                toggleOpenModal();
            }} title={formatMessage({id: 'standardPoints'})}>
                {isOpenModal && (<JobOmlForm onAfterSubmit={(items: IJobOmlItem[]) => {
                    onAfterSubmit?.(items);
                    toggleOpenModal();
                    refreshParent(silentRefresh ?? false);
                }}
                                             itemId={itemId}
                                             tableType={tableType}
                                             showRaisedItems={showRaisedItems}
                                             showNonWitholdingColumn={showNonWitholdingColumn}
                                             disabled={formDisabled}
                />)}
            </Modal>
        </>
    );
};