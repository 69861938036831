import {FunctionComponent} from "react";
import {IProceduresDataTableProps, ProceduresDataTable} from "../../../ProceduresDataTable";
import { useProcedureContext } from "../../../ProceduresContent";

export const AuditQueriesDataTableDateFormat = 'dd/MM/yyyy HH:mm:ss'
export const InitialAuditQueriesSectionReference: string = 'Initial Audit Queries';
export const DeletedAuditQueriesSectionReference: string = 'Deleted Audit Queries';

export interface IAuditQueriesDataTableProps extends IProceduresDataTableProps {
    
}

export const AuditQueriesDataTable: FunctionComponent<IAuditQueriesDataTableProps> = ({ 
                                                                                          items,
                                                                                          columns,
                                                                                          isLoading,
                                                                                          ...props
                                                                                      }: IProceduresDataTableProps) => {
    const {isSilent} = useProcedureContext();
    
    return (
        <ProceduresDataTable 
            items={items} 
            isLoading={isLoading && !isSilent}
            columns={columns}
            hideIfEmpty={false}
        />
    )
}