import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    FontSizes,
    IButtonStyles,
    IColumn,
    Icon,
    IconButton,
    Link,
    mergeStyles,
    MessageBarType,
    PrimaryButton,
    SelectionMode,
    Spinner,
    Stack,
    StackItem,
    Text,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { ControlledTextField, DataTable, FileViewer, Modal } from 'components';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { useIntl } from 'react-intl';
import { useGetJobByGuid } from 'hooks/useGetJobByGuid';
import { useParams } from 'react-router-dom';
import {
    useCreateAttachment,
    useGetAttachments,
    useRemoveAttachment,
    useUpdateAttachmentMarkAsPerm,
    useUpdateAttachmentRename,
    useUpdateAttachmentUnmarkAsPerm,
} from '../hooks';
import { UploadAttachment } from './UploadAttachment';
import { useGetFile } from 'hooks/useGetFile';
import { useForm } from 'react-hook-form';
import { DefaultFormSettings } from 'constants/forms';
import { useSectionContext } from './Section';
import { useDropzone } from 'react-dropzone';
import { useJobContext } from '../JobPortalLayoutPage';
import { TableType } from '../../../enums';
import { useHideAttachment } from '../hooks/attachments/useHideAttachment';
import { useShowAttachment } from '../hooks/attachments/useShowAttachment';
import { useNotifications } from 'components/notifications';
import { useGetDownloadUrl } from 'hooks/useGetDownloadUrl';
import { PortalRole, useWorkContext } from '../../../providers';
import { useTabContext } from '..';
import { PermanentDocumentToggler } from '../PermanentDocumentToggler';

interface IAttachmentDownloadProps {
    downloadUrl: string;
    fileName: string;
}

interface IAttachmentsProps {
    itemId: number;
    hasAttachments?: boolean;
    tableType?: number;
    reloadParent?: boolean;
    silentReload?: boolean;
    onLoad?: (countLoaded: number) => void;
}

interface IAttachmentInfoProps {
    fileName: string;
    originalFileName: string;
    id: number;
    isEdited: boolean;
    downloadUrl: string;
    isPermanent: boolean;
    attachmentJobId: number;
    jobId: number;
    editingDisabled?: boolean;
    deletingDisabled?: boolean;
    isCorrupted?: boolean;
}

interface IAttachmentEditTitleProps {
    fileName: string;
    id: number;
    toggleEditMode: Function;
}

interface IAttachmentTitleForm {
    fileName: string;
}

const AttachmentEditTitle: FunctionComponent<IAttachmentEditTitleProps> = ({ fileName, toggleEditMode, id }) => {
    const theme = useTheme();

    const ext = fileName.indexOf('.') != -1 ? fileName.split('.').pop() : undefined;
    const editableFileName = ext ? fileName.substring(0, fileName.lastIndexOf('.')) : fileName;

    const { renameAttachment } = useUpdateAttachmentRename();
    const { control, handleSubmit } = useForm<IAttachmentTitleForm>({
        ...DefaultFormSettings,
        defaultValues: { fileName: editableFileName },
    });

    const onSubmit = ({ fileName }: IAttachmentTitleForm) => {
        const editedFileName = ext ? `${fileName}.${ext}` : fileName;
        renameAttachment({ fileName: editedFileName, id });
        toggleEditMode();
    };

    return (
        <>
            <Stack horizontal grow tokens={{ childrenGap: 16 }}>
                <Stack.Item grow>
                    <ControlledTextField name='fileName' control={control} />
                </Stack.Item>
                <StackItem>
                    <IconButton
                        iconProps={{ iconName: 'Cancel', styles: { root: { color: theme.palette.red } } }}
                        onClick={() => toggleEditMode()}
                    />
                    <IconButton iconProps={{ iconName: 'Accept' }} onClick={handleSubmit(onSubmit)} />
                </StackItem>
            </Stack>
        </>
    );
};

export const AttachmentInfo: FunctionComponent<IAttachmentInfoProps> = ({
    fileName,
    originalFileName,
    id,
    isEdited,
    downloadUrl,
    isPermanent,
    attachmentJobId,
    jobId,
    editingDisabled = false,
    deletingDisabled = false,
    isCorrupted = false
}) => {
    const tooltipId = useId('tooltip');
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const { isInRoleOrSuperAdministrator, isJobPortalEnabled } = useWorkContext();

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
        }),
        [labelId, subTextId]
    );

    const theme = useTheme();

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Rename file',
        closeButtonAriaLabel: 'Close',
        subText: 'Do you want to change file name?',
        theme: theme.schemes?.default,
    };

    const [isEditMode, { toggle: toggleEditMode }] = useBoolean(false);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [viewerState, setViewerState] = useState<{ show: boolean; file: string | null; url: string | null }>({
        show: false,
        file: null,
        url: null,
    });

    
    const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState<boolean>(isInRoleOrSuperAdministrator(PortalRole.Administrator) && isJobPortalEnabled);

    return (
        <>
            <Stack horizontal grow verticalAlign='center'>
                {isCorrupted && <StackItem styles={{ root: { width: '3%' }}}>
                    <Icon iconName={'Error'} style={{ color: theme.semanticColors.errorIcon }} />
                </StackItem>}
                <StackItem styles={{ root: { width: isCorrupted ? '97%' : '100%' }}}>
                    <Stack horizontal grow verticalAlign='center' horizontalAlign='space-between'>
                        <StackItem styles={{ root: { width: '75%' } }}>
                            {isEditMode ? (
                                <AttachmentEditTitle id={id} fileName={fileName} toggleEditMode={toggleEditMode} />
                            ) : (
                                <Stack grow>
	                                <StackItem>
		                                <Link
			                                underline
			                                style={{
				                                color: theme.schemes?.default?.palette.blue,
				                                overflow: 'hidden',
				                                textOverflow: 'ellipsis',
				                                whiteSpace: 'break-spaces'
			                                }}
			                                onClick={() =>
				                                setViewerState({
					                                show: true,
					                                file: fileName,
					                                url: downloadUrl,
				                                })
			                                }>
			                                {fileName}{' '}
		                                </Link>
	                                </StackItem>
	                                {isEdited && <StackItem>
                                        <Text
                                            styles={{
				                                root: {
					                                fontStyle: 'italic',
					                                overflow: 'hidden',
					                                textOverflow: 'ellipsis',
					                                whiteSpace: 'break-spaces'
				                                }
			                                }}>
                                            ({originalFileName})
                                        </Text>
                                    </StackItem>}
                                </Stack>
                            )}
                        </StackItem>
                        <Stack horizontal horizontalAlign='space-between'>
                            {!editingDisabled && (
                                <StackItem align='center'>
                                    <TooltipHost content='Rename' id={tooltipId}>
                                        {isEdited ? '' : <IconButton onClick={toggleHideDialog} iconProps={{ iconName: 'Edit' }} />}
                                    </TooltipHost>
                                    <Dialog
                                        hidden={hideDialog}
                                        onDismiss={toggleHideDialog}
                                        dialogContentProps={dialogContentProps}
                                        modalProps={modalProps}>
                                        <DialogFooter>
                                            <PrimaryButton
                                                onClick={() => {
                                                    toggleEditMode();
                                                    toggleHideDialog();
                                                }}
                                                text='Yes'
                                            />
                                            <DefaultButton onClick={toggleHideDialog} text='No' />
                                        </DialogFooter>
                                    </Dialog>
                                </StackItem>
                            )}

                            <AttachmentDownload downloadUrl={downloadUrl} fileName={fileName} />
                            {isDeleteButtonVisible ? (
                                <>{!(deletingDisabled || isPermanent || attachmentJobId != jobId) && <AttachmentDelete itemId={id} />}</>
                            ) : (
                                <></>
                            )}
                        </Stack>
                    </Stack>
                </StackItem>
            </Stack>
            <FileViewer
                fileName={viewerState.file || ''}
                url={viewerState.url || ''}
                onDismiss={() => setViewerState({ show: false, file: null, url: null })}
                isOpen={viewerState.show}
            />
        </>
    );
};

const AttachmentContent: FunctionComponent<IAttachmentsProps> = ({ itemId, tableType, onLoad }) => {
    const {workContext} = useWorkContext();
    const sectionCtx = useSectionContext();
    const { isTabEnabled } = useTabContext();
    const { job } = useJobContext();
    const { markAsPerm } = useUpdateAttachmentMarkAsPerm();
    const { unmarkAsPerm } = useUpdateAttachmentUnmarkAsPerm();
    const { hide } = useHideAttachment();
    const { show } = useShowAttachment();
    const { formatMessage } = useIntl();
    const { guid } = useParams();
    const { dataJobs } = useGetJobByGuid({ guid });

    tableType = tableType || sectionCtx?.section?.tableType || 0;

    const [isEditDisabled, setIsEditDisabled] = useState<boolean>(!!workContext?.job?.isDispatched)

    const { attachmentsData, isAttachmentsLoading, isAttachmentsFetching } = useGetAttachments({
        jobId: dataJobs?.data?.id,
        fundId: dataJobs?.data?.fund?.id,
        itemId,
        tableType: tableType,
        year: dataJobs?.data?.year,
    });

    useEffect(() => {
        if (onLoad) {
            onLoad(attachmentsData?.data?.length || 0);
        }
    }, [attachmentsData]);

    const toggleMarkAsPerm = (id: number, isPermanent: boolean) => {
        const markProps = {
            jobId: dataJobs?.data?.id,
            fundId: dataJobs?.data?.fund?.id,
            itemId,
            id,
            year: dataJobs?.data?.year,
        };

        if (isPermanent) {
            markAsPerm(markProps);
        } else {
            unmarkAsPerm(markProps);
        }
    };

    const toggleHide = (id: number, isHidden: boolean) => {
        const hideProps = {
            jobId: dataJobs?.data?.id,
            fundId: dataJobs?.data?.fund?.id,
            itemId,
            id,
        };

        if (isHidden) {
            hide(hideProps);
        } else {
            show(hideProps);
        }
    };

    const disableMarkAsPermanent = useMemo(
        () => !(isTabEnabled && job.dateARSent == null),
        [isTabEnabled, job.dateARSent]
    );

    const [columns] = useState<IColumn[]>([
        {
            key: 'name',
            name: formatMessage({ id: 'name' }),
            minWidth: 200,
            fieldName: 'name',
            onRender: (item) => (
                <AttachmentInfo
                    id={item.id}
                    isPermanent={item.isPermanent}
                    attachmentJobId={item.jobId}
                    fileName={item.fileName}
                    originalFileName={item.originalFileName}
                    isEdited={item.isEdited}
                    downloadUrl={item.downloadUrl}
                    jobId={dataJobs?.data?.id}
                    isCorrupted={item.isExternalCorrupted ?? false}
                    editingDisabled={isEditDisabled}
                />
            ),
        },
        {
            key: 'perm',
            name: formatMessage({ id: 'perm' }),
            minWidth: 180,
            maxWidth: 180,
            fieldName: 'perm',
            onRender: (item) => (
                <PermanentDocumentToggler
                    isPermanent={item.isPermanent}
                    disabled={disableMarkAsPermanent}
                    toggle={(isPermanent) => toggleMarkAsPerm(item.id, isPermanent)}>
                </PermanentDocumentToggler>
            ),
        },
        {
            key: 'hide',
            name: formatMessage({ id: 'hide' }),
            minWidth: 180,
            maxWidth: 180,
            fieldName: 'hide',
            onRender: (item) => (
                <>
                    {item.isExternal && (
                        <Stack horizontal styles={{ root: { width: '100%' } }}>
                            <PrimaryButton
                                toggle
                                primary={item.isHidden}
                                minLength={100}
                                styles={{ root: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
                                checked={item.isHidden}
                                text={item.isHidden ? formatMessage({ id: 'hidden' }) : formatMessage({ id: 'hide' })}
                                onClick={() => toggleHide(item.id, true)}
                            />
                            <DefaultButton
                                toggle
                                minLength={100}
                                styles={{ root: { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 } }}
                                checked={!item.isHidden}
                                text={item.isHidden ? formatMessage({ id: 'show' }) : formatMessage({ id: 'shown' })}
                                onClick={() => toggleHide(item.id, false)}
                            />
                        </Stack>
                    )}
                </>
            ),
        },
    ]);

    if (isAttachmentsLoading)
        return (
            <Stack horizontalAlign='center'>
                <Spinner />
            </Stack>
        );
    if (!attachmentsData?.data) return <Stack>No data</Stack>;
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <UploadAttachment itemId={itemId} tableType={tableType} />
            <DataTable
                initialColumns={columns}
                columns={columns}
                items={attachmentsData?.data}
                selectionMode={SelectionMode.none}
                enableShimmer={isAttachmentsFetching}
                containerHeight='100%'
                emptyProps={{
                    iconName: 'ComplianceAudit',
                }}
            />
        </Stack>
    );
};

const AttachmentDownload: FunctionComponent<IAttachmentDownloadProps> = ({ downloadUrl, fileName }) => {
    const { getFile } = useGetFile();
    const tooltipId = useId('tooltip');
    const { downloadUrl: directUrl, refetch, remove } = useGetDownloadUrl({ url: downloadUrl });
    const { showNotification } = useNotifications();
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (directUrl) {
            getFile({
                url: String(directUrl.data?.downloadUrl),
                fileName: fileName,
            });
            remove();
        }
    }, [directUrl]);

    return (
        <StackItem align='center'>
            <TooltipHost content='Download' id={tooltipId}>
                <IconButton
                    iconProps={{ iconName: 'Download' }}
                    onClick={() => {
                        refetch();
                        showNotification({
                            name: formatMessage({ id: 'downloadStarted' }),
                            type: MessageBarType.info,
                            description: formatMessage({ id: 'downloadStarted' }),
                        });
                    }}
                />
            </TooltipHost>
        </StackItem>
    );
};

const AttachmentDelete: FunctionComponent<IAttachmentsProps> = ({ itemId }) => {
    const tooltipId = useId('tooltip');
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { deleteAttachment } = useRemoveAttachment();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
        }),
        [labelId, subTextId]
    );
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Deleting file',
        closeButtonAriaLabel: 'Close',
        subText: 'Do you want to delete this file?',
        theme: theme.schemes?.default,
    };
    return (
        <StackItem align='center'>
            <TooltipHost content={formatMessage({ id: 'delete' })} id={tooltipId}>
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    styles={{ icon: { color: theme.palette.red }, iconHovered: { color: theme.palette.redDark } }}
                    onClick={toggleHideDialog}
                />
            </TooltipHost>
            <Dialog hidden={hideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            deleteAttachment(itemId);
                            toggleHideDialog();
                        }}
                        text='Yes'
                    />
                    <DefaultButton onClick={toggleHideDialog} text='No' />
                </DialogFooter>
            </Dialog>
        </StackItem>
    );
};

export const Attachment: FunctionComponent<IAttachmentsProps> = ({ itemId, hasAttachments, tableType, reloadParent, silentReload }) => {
    const { refresh: refreshSection } = useSectionContext();

    const [isOpenModal, { toggle: toggleOpenModal }] = useBoolean(false);
    const [shouldReloadParent, setShouldReloadParent] = useBoolean(reloadParent ?? false);

    const theme = useTheme();
    const styles: IButtonStyles = {
        root: {
            color: hasAttachments ? theme.palette.red : theme.palette.themePrimary,
        },
        rootHovered: {
            color: hasAttachments ? theme.palette.redDark : theme.palette.themeDarker,
        },
        rootPressed: {
            color: hasAttachments ? theme.palette.redDark : theme.palette.themePrimary,
        },
    };

    const refresh = useCallback((shouldReload: boolean, silent?: boolean) => {
        if (shouldReload) {
            refreshSection?.(silent ?? false);
        }
    }, []);

    return (
        <>
            <IconButton
                iconProps={{ iconName: 'Attach', style: { fontSize: FontSizes.size14 } }}
                styles={styles}
                onClick={toggleOpenModal}
            />

            <Modal
                isOpen={isOpenModal}
                onDismiss={() => {
                    refresh(shouldReloadParent, silentReload);
                    toggleOpenModal();
                }}>
                <AttachmentContent
                    itemId={itemId}
                    tableType={tableType}
                    onLoad={(count) => {
                        if (!!count !== hasAttachments) {
                            setShouldReloadParent.setTrue();
                        }
                    }}
                />
            </Modal>
        </>
    );
};

interface IAttachmentDropZoneProps {
    itemId: number;
    tableType: TableType;
}

export const AttachmentDropZone: FunctionComponent<IAttachmentDropZoneProps> = ({
    itemId,
    tableType,
    children,
}: IAttachmentDropZoneProps & PropsWithChildren<{}>) => {
    const { job } = useJobContext();
    const { refresh, section } = useSectionContext();
    const theme = useTheme();

    const { createAttachment, isLoading: isCreateLoading } = useCreateAttachment();

    const [isDragOver, setIsDragOver] = useState<boolean>(false);
    const [dragCounter, setDragCounter] = useState<number>(0);

    const upload = useCallback((selectedFile) => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('fileName', selectedFile.name);
        createAttachment(
            {
                fundId: job?.fund?.id,
                jobId: job?.id,
                clientId: job?.client?.id,
                itemId,
                tableType: tableType || section?.tableType || 0,
                data: formData,
            },
            {
                onSuccess: () => {
                    refresh?.();
                },
            }
        );
    }, []);

    const className = useMemo(() => {
        return mergeStyles({
            '.ms-DetailsRow': {
                backgroundColor: isDragOver ? theme.semanticColors.bodyBackgroundHovered : 'inherit',
            },
        });
    }, [isDragOver]);

    const onDrop = useCallback((files) => {
        setIsDragOver(false);
        setDragCounter(0);

        if (!files?.length) return;

        upload(files[0]);
    }, []);

    const { getRootProps } = useDropzone({ onDrop });

    const onDragEnter = useCallback(() => {
        setDragCounter((prev) => prev + 1);
        if (dragCounter === 0) {
            setIsDragOver(true);
        }
    }, [dragCounter]);

    const onDragLeave = useCallback(() => {
        setDragCounter((prev) => --prev);
        if (dragCounter <= 1) {
            setIsDragOver(false);
        }
    }, [dragCounter]);

    return (
        <div onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={() => {}} className={className}>
            <div
                {...getRootProps({
                    onClick: (e) => {
                        e.stopPropagation();
                    },
                })}>
                {children}
            </div>
        </div>
    );
};
