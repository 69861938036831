import {ISectionInfo} from "../../interfaces";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import {AnimationStyles, mergeStyleSets, Stack, useTheme} from "@fluentui/react";
import {ISectionContext, SectionContext, useSectionContext} from "./context";
import {SectionContent, SectionTitle} from "./components";
import {Modal} from "../../../../components";
import {ControlType} from "../../../../enums/ControlType";
import { useUpdateSectionAnswerPositivity } from "../../hooks/answers/useUpdateSectionAnswerPositivity";
import { useJobContext } from "../..";
import {useJobRedirects} from "../templates/shared";
import { PortalRole, useWorkContext } from "../../../../providers";


type SectionProps = {
    section: ISectionInfo;
    secondary?: boolean;
    fullScreen?: boolean;
    cref?: React.MutableRefObject<any>;
    isPositiveParentSectionAnswer?: boolean | null;
    updateParentSectionAnswer?: Function;
}
export const Section = forwardRef(({section, secondary, fullScreen = false, cref, isPositiveParentSectionAnswer, updateParentSectionAnswer}: SectionProps, ref) => {
    
    const [isSectionOpened, setSectionAsOpened] = useState<boolean>(!section.disabled && (section.openOnStartUp || !section.showHeader));
    const [isManualOpenChange, setIsManualOpenChange] = useState<boolean>(false);
    const { palette, effects } = useTheme();
    const { jobId } = useJobContext();
    const { isInRoleOrSuperAdministrator } = useWorkContext();
    const { navigateToJobExternal } = useJobRedirects();
    const { update } = useUpdateSectionAnswerPositivity();
    const [isFullScreen, setIsFullScreen] = useState<boolean>(fullScreen);
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const [isPositiveSectionAnswer, setIsPositiveSectionAnswer] = useState(section.sectionAnswer == null ? null : section.sectionAnswer.isPositive);
    const [isApprovalDisabled, setIsApprovalDisabled] = useState<boolean>(section.disabled);
    const [isWarningMessageEnabled, setIsWarningMessageEnabled] = useState<boolean>(true);
    const [isWarningMessageForced, setIsWarningMessageForced] = useState<boolean>(false);
    const [sectionAnswersRefreshedTick, setSectionAnswersRefreshedTick] = useState<number>(Date.now())
    const childRef = useRef<any>(cref || null);
    
    const childrenRefs = useRef<any[]>((section.children ?? []).slice(0, section.children?.length ?? 0))
    
    const openAs: 'modal' | 'window' = 'window';

    const isSectionEnabled = useCallback((section: ISectionInfo) => {
        const _sectionAccessMapFn = (ref: string | null): boolean => {
            switch (ref) {
                case "Audit Trail": {
                    return isInRoleOrSuperAdministrator(PortalRole.Administrator, PortalRole.ReviewerUser, PortalRole.PowerUser);
                }
                default: {
                    return true;
                }
            }
        }

        return !section.disabled && _sectionAccessMapFn(section.reference);
    }, [])
    
    const classNames = mergeStyleSets({
        itemCell: [
            {
                margin: 4,
                boxShadow: section.disabled ? undefined : effects.elevation4,
                background: section.disabled ? 'rgba(184, 184, 184, 0.1)' : 'rgba(184, 184, 184, 0.2)',
                borderRadius: effects.roundedCorner2,
                selectors: {
                    '&:hover': (isSectionOpened || !section.showExpander || !isSectionEnabled(section)) ? {} : {background: 'rgba(184, 184, 184, 0.4)'},
                },
            },
        ],
    });
    const handleOpen = () => {
        setIsManualOpenChange(true);
        setSectionAsOpened(true);
    }
    const handleClose = () => {
        setIsManualOpenChange(false);
        setSectionAsOpened(false);
    }
    const handleRefresh = (silent?: boolean) => {
        if (childRef?.current?.refresh) childRef?.current?.refresh(silent);
        for (let i = 0; i < childrenRefs.current.length; i++) {
            childrenRefs.current[i]?.refresh?.(silent)
        }
    };
    const handleFullScreen = () => {
        if (section.showFullScreen) {
            
            if (openAs ?? 'window' === 'window') {
                navigateToJobExternal(`section/${section.id}`)
            } else {
                setIsFullScreen(true);
            }
        }
    }
    const handleOnExitFullScreen = () => {
        setIsFullScreen(false);
    }
    
    const handleHide = () => {
        setIsHidden(true);
    }

    const updateSectionAnswer = (isPositive: boolean | null) => {
        if (isPositive === isPositiveSectionAnswer) {
            return;
        }

        update({
            jobId: jobId,
            sectionId: section.id,
            isPositive: isPositive,
        }, {
            onSuccess: () => {
                setIsPositiveSectionAnswer(prev => isPositive);
            }
        });
    };

    const updateSectionAnswerIcon = (isPositive: boolean | null) => {
        if (isPositive === isPositiveSectionAnswer) {
            return;
        }

        
        setIsPositiveSectionAnswer(isPositive);
        return;
    };

    const handleOnApprovalDisabling = (isDisabled: boolean) => {
        setIsApprovalDisabled(isDisabled);
    }
    
    const refreshSectionAnswers = useCallback((values: {  manager: boolean, sendEmail: boolean, partner: boolean }, updateValues: boolean = false) => {
        if (!section.sectionAnswer && !updateValues) {
            return;
        }

        section.sectionAnswer = {
            ...(section.sectionAnswer ?? {}),
            managerApproval: values.manager,
            partnerApproval: values.partner,
            sendEmail: values.sendEmail
        } as any;

        setSectionAnswersRefreshedTick(Date.now())
    }, [])

    const [isLoaded, setIsLoaded] = useState(false);

    const updateLoadedState = (state: boolean) => {
        setIsLoaded(state);
    }

    useEffect(() => {
        const isConclusionSection = (section.reference ?? '').startsWith('Conclusion_');
        const shouldBeOpenedOnStartUp = (isConclusionSection ? !isPositiveSectionAnswer : section.openOnStartUp) || isManualOpenChange;

        // console.debug("[SECTION_TEMPLATE::EFFECT]", { 'section.ref': section.reference, isSectionOpened: isSectionOpened, shouldBeOpenedOnStartUp: shouldBeOpenedOnStartUp, isConclusionSection: isConclusionSection });
        
        setSectionAsOpened(!section.disabled && (!!shouldBeOpenedOnStartUp || !section.showHeader));
    }, [section, isPositiveSectionAnswer, isManualOpenChange])

    useEffect(() => {
        if (isLoaded && section.caption === 'Contravention Report' && !section.sectionAnswer) {
            updateSectionAnswerFn()(!(section.children ?? []).some(x => !x.sectionAnswer || !x.sectionAnswer.isPositive))
        }
    }, [isLoaded]);
    
    const updateSectionAnswerFn = () => {
        return updateParentSectionAnswer != undefined && ((section.caption == "Property" && section.reference == "Property") || section.parentId != null)
            ? (isPositive: boolean | null) => {
                updateParentSectionAnswer(isPositive);
                updateSectionAnswer(isPositive)
            }
            : updateSectionAnswer
    }

    const fixedContainerStyles = section.controlType === ControlType.ReportStatement ? {
        maxHeight: '500px',
        overflowX: 'auto',
        overflowY: 'auto',
        '.ms-DetailsList': {'overflow': 'inherit'},
        '.ms-DetailsHeader': {marginRight: 16}
    } : {}

    const [openProceduresIds, setOpenProceduresIds] = useState<number[]>([]);
    
    const contextValues: ISectionContext = {
        section: section,
        isOpened: isSectionOpened,
        open: handleOpen,
        close: handleClose,
        refresh: handleRefresh,
        fullScreen: handleFullScreen,
        setApprovingDisable: handleOnApprovalDisabling,
        isPositiveSectionAnswer: isPositiveParentSectionAnswer != undefined && section.caption == "Property" && section.reference == "Property" ? isPositiveParentSectionAnswer : isPositiveSectionAnswer,
        forcePositiveSectionAnswer: (isPositive: boolean)=> setIsPositiveSectionAnswer(isPositive),
        updateSectionAnswer: updateSectionAnswerFn(),
        updateSectionAnswerIcon: updateSectionAnswerIcon,
        hide: handleHide,
        refreshSectionAnswers: refreshSectionAnswers,
        sectionAnswersRefreshedTick: sectionAnswersRefreshedTick,
        enableWarningMessage: (enable: boolean, force?: boolean) => {
            setIsWarningMessageEnabled(enable);
            setIsWarningMessageForced(force ?? false)
        },
        isWarningMessageForced: isWarningMessageForced,
        isWarningMessageEnabled: isWarningMessageEnabled,
        updateLoadedState: updateLoadedState,
        isLoaded: isLoaded,
        openProcedures: {
            ids: openProceduresIds,
            setIds: setOpenProceduresIds,
            clear: () => setOpenProceduresIds([])
        },
        styles: {
            container: {
                maxHeight: fixedContainerStyles.maxHeight
            }
        }
    };
    
    useImperativeHandle(ref, () => ({
        refresh(silent?: boolean) {
            handleRefresh(silent)
        },
        open() {
            handleOpen();
        },
        close() {
            handleClose();
        },
        isOpened(): boolean {
            return isSectionOpened
        },
        updateSectionAnswer(isPositive: boolean | null) {
            updateSectionAnswerFn()(isPositive)
        },
        id: section.id,
        reference: section.reference
    }))

    if (isFullScreen) {
        return (<Modal isOpen={isFullScreen}
                       onDismiss={handleOnExitFullScreen}
                       maxHeight
                       title={section?.caption || ''} fullWidth>
            <SectionContext.Provider value={contextValues}>
                <Stack tokens={{childrenGap: 16}}
                       styles={{root: {marginLeft: 10, marginBottom: 10}}}>
                    <SectionContent childRef={childRef}/>
                    {section.children && section.children.length && section.children.map((childSection) => (
                        <Section section={childSection} key={`job-portal-section-${section.id}-${childSection.id}`}
                                 secondary={!secondary}/>
                    ))}
                </Stack>
            </SectionContext.Provider>
        </Modal>)
    }

    return (
        <SectionContext.Provider value={contextValues}>
            {!isHidden &&
                <Stack styles={{root: {...AnimationStyles.fadeIn200}}}
                       className={classNames.itemCell}>
                    <SectionTitle disabled={!isSectionEnabled(section)} disableApprovals={isApprovalDisabled} childRef={childRef}/>
                    {isSectionOpened && isSectionEnabled(section) && (
                        <Stack tokens={{childrenGap: 16}}
                               styles={{
                                   root: {
                                       padding: 16,
                                       background: palette.white,
                                       ...AnimationStyles.fadeIn200,
                                       ...fixedContainerStyles
                                   }
                               }}>
                            <SectionContent childRef={childRef}/>
                            {!!section.children?.length && section.children.map((childSection, index) => (
                                <Section section={childSection} key={`job-portal-section-${section.id}-${childSection.id}`} ref={el => childrenRefs.current[index] = el}
                                    updateParentSectionAnswer={updateSectionAnswer} isPositiveParentSectionAnswer={isPositiveSectionAnswer}
                                    secondary/>
                            ))}
                        </Stack>)}
                </Stack>
            }
        </SectionContext.Provider>
    )
})